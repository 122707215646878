import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img src="/img/client-logos/bbc.svg" alt="BBC" className="client-logo" />
    <h3>{`The Client`}</h3>
    <p>{`The BBC Archive contains material dating the back to the 1880s, preceding the formation of the Corporation itself.`}</p>
    <h3>{`The Problem`}</h3>
    <p>{`Following on from the successful roll-out of the `}<a parentName="p" {...{
        "href": "/case-studies/bbc-rewind/"
      }}>{`Archive Search project`}</a>{` which delivered a search portal for journalists and researchers to find and use those “golden nuggets” of archive footage, I was re-engaged to consult on the delivery of a complementary, public-facing web app that the archive editorial team had conceived.`}</p>
    <p>{`The aim of the "Discover" app is to allow members of the public to use the same rich search and discovery functions that the Archive Search app gives to internal users but over a much smaller section of the archive. The editorial team required the ability to manage the content and a back-office approval workflow was needed to ensure that restricted footage wasn't incorrectly published.`}</p>
    <p>{`On a technical level, since the Discover app was public-facing, security and scalability were even more important than with the original Archive Search project.`}</p>
    <h3>{`The Solution`}</h3>
    <p>{`The app followed a largely similar architecture to the Archive Search portal, with ElasticSearch, MongoDB and a Node.js based web app.`}</p>
    <p>{`The app was deployed on containers on AWS ECS. Archive content was stored in S3 and delivered via CloudFront.`}</p>
    <p>{`Other significant technologies used include: ELB, Docker, Lambda, CloudFormation.`}</p>
    <div className="testimonial">
    <div className="testimonial-body">
        <p>
        &ldquo;
            It was important to build a functional proof of concept and create early architecture that would allow us to establish the project's viability as quickly as possible, and we were aware that Paul had a practical working knowledge of modern javascript frameworks and AWS infrastructure we were planning to use.
        </p>
        <p>
            The proof of concept was a success. We have continued to build the product and have committed more internal effort to it on the way to its release.
        </p>
        <p>
            Paul’s early work on the site architecture has proved invaluable and he created excellent handover materials that the development team have been able to use during key decision making phases and add to them as and when required. He also provided useful advice on modern javascript best practices and improvements as we worked toward refactoring earlier work.
        </p>
        <p>
            Overall Paul is just a good person to have on the team, even if it is just for a short while. He’s honest and realistic about what can be achieved with his help, which was invaluable to both project management and system design in our tight project timescale. He worked with the other developers to solve issues and articulated potential long-term issues so that we could avoid them in the future. He ensured that we had sufficient handover documentation which was greatly appreciated.
        </p>
        <p>
            Paul engaged fully with eliciting requirements and the project goals, so he understood our team’s intentions quickly and co-ordinated easily with our own developers.
            &rdquo;
        </p>
    </div>
    <div className="testimonial-footer">
        <span className="person-name">Clive Jones</span>,&nbsp;
        <span className="person-role">Head of Software Engineering at BBC Northern Ireland</span>
    </div>
    </div>
    <h3>{`The Results`}</h3>
    <ul>
      <li parentName="ul">{`First usable version of the Discover app delivered within 1 month of start of engagement`}</li>
      <li parentName="ul">{`Proof-of-concept app very well received by editorial team and more funding acquired to proceed with roll-out`}</li>
      <li parentName="ul">{`Knowledge transfer to in-house engineering team on AWS best practices around networking, security and infrastructure-as-code`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      